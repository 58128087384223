import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { loader } from "graphql.macro";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Button, { SmallButton } from "../../../../components/Button";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout/Background";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Title from "../../../../components/Title";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import onError from "../../../../libs/error-logger";
import themes from "../../../../libs/themes";
import toast from "../../../../libs/toast";
import PickingTable from "./components/PickingTable";

const SUPPLY_QUERY = loader("./Supply.query.graphql");
const SUPPLY_SUBSCRIPTION = loader("./Supply.subscription.graphql");
const PRINT_MUTATION = loader("./Print.mutation.graphql");

function Supply() {
  const intl = useIntl();
  const { phaseId, sessionId, stepId } = useParams();
  const history = useHistory();
  const variables = { phaseId, sessionId };
  const { data, loading, error } = useQuery(SUPPLY_QUERY, { variables });
  const [print] = useMutation(PRINT_MUTATION);
  const [printing, setPrinting] = React.useState(false);

  useSubscription(SUPPLY_SUBSCRIPTION, {
    variables: { sessionId },
  });

  let theme = themes.materialViolet;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { session } = data;

  let { picking } = session;
  picking = [...picking];

  // Sort picking by producible units
  picking.sort((a, b) => {
    const aProducible = a.remaining / a.qty;
    const bProducible = b.remaining / b.qty;
    return aProducible - bProducible;
  });

  // Check if there are no producible picking just on items with containers
  const noProduciblePicking = session?.picking?.filter((p) => {
    const totalCurrent = p.containers?.reduce(
      (sum, container) => sum + (container.current || 0),
      0
    );
    return totalCurrent / p.qty < session.alertThreshold;
  });

  const isAlert = noProduciblePicking?.length > 0;
  if (isAlert) {
    theme = themes.errorRed;
  }

  async function onPrintSubmit() {
    if (printing) {
      return;
    }
    setPrinting(true);
    try {
      const lastBatch = session.producing[session.producing.length - 1].batch;
      if (lastBatch) {
        await print({ variables: { batchId: lastBatch._id } });
        toast({
          title: intl.formatMessage({ id: "app.shared.print_success" }),
        });
      } else {
        toast({ title: intl.formatMessage({ id: "app.shared.print_error" }) });
      }
    } catch (error) {
      onError(error);
    }
    setPrinting(false);
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasPhaseInfo>
              <div className="column is-1 is-offset-3">
                <SmallButton
                  isFullWidth
                  theme={themes.default.buttons}
                  data-cy="application-supply-settings"
                  onClick={() =>
                    history.push(
                      `/application/supply-settings/${phaseId}/${sessionId}/${stepId}`
                    )
                  }
                >
                  <FormattedMessage
                    id="app.pages.application.supply.settings.button"
                    defaultMessage="Settings"
                  />
                </SmallButton>
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() =>
                    !isAlert
                      ? history.goBack()
                      : history.push(
                          `/application/step/${phaseId}/${sessionId}/${stepId}`
                        )
                  }
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../../../images/arrow-back-gray-icn.svg")
                        .default
                    }
                    alt=""
                  />
                </Button>
              </div>
              <div className="column is-4 is-offset-3"></div>
              <div className="column is-4"></div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-3"></div>
              <div
                className="column is-6 has-text-centered"
                style={{ margin: "2rem 0" }}
              >
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.supply.title"
                    defaultMessage="Supply alerts"
                  />
                </Title.H1>
              </div>
              <div className="column is-3">
                <div className="columns">
                  <div
                    className="column is-8 has-text-right"
                    style={{ marginTop: "1.2rem" }}
                  >
                    <ul>
                      <li>
                        <FormattedMessage
                          id="app.pages.application.supply.batch"
                          defaultMessage="Batch"
                        />
                      </li>
                      <li>
                        <div className="title is-3">
                          {session.producing && session.producing.length > 0
                            ? session.producing[session.producing.length - 1]
                                .batch?.code
                            : ""}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="column is-4">
                    <Button
                      isFullWidth
                      theme={themes.doneGrey.buttons}
                      data-cy="application-supply_settings-enable"
                      onClick={onPrintSubmit}
                      disabled={printing}
                    >
                      <i className="icon-print" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <PickingTable picking={picking} session={session} />
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Supply.propTypes = {};
export default Supply;
